import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import Header, { NavLink, NavLinks, PrimaryLink as PrimaryLinkBase, LogoLink, NavToggle, DesktopNavLinks } from "../headers/light.js";

const StyledHeader = styled(Header)`
  ${tw`max-w-none w-full h-10`}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw`text-gray-800 hover:border-gray-300 hover:text-primary-300`}
  }
  ${NavToggle}.closed {
    ${tw`text-gray-100 hover:text-primary-500`}
  }
`;

const Container = styled.div`
  ${tw`h-30 md:h-50 bg-blue-900 flex flex-col justify-center text-center p-12 md:p-24`}
`;

const Heading = styled.h1`
  ${tw`text-2xl sm:text-3xl lg:text-4xl xl:text-5xl font-black text-gray-100 leading-snug text-center md:text-left`}
`;

const Description = styled.p`
  ${tw`text-xl sm:text-2xl lg:text-2xl xl:text-2xl font-medium text-blue-500 leading-relaxed text-center md:text-left`}
`;

export default (props) => {
  const { selectedService } = props
  return (
    <>
      <StyledHeader />
      <Container>
        <Heading>{selectedService.title}</Heading>
        <Description>{selectedService.shortDescription}</Description>
      </Container>
    </>
  );
};

