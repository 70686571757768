import React from "react";
import GlobalStyles from 'styles/GlobalStyles';
import EventLandingPage from "pages/HomePage.js";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Service from "pages/Service";

export default function App() {

  return (
    <>
      <GlobalStyles />
      <Router>
        <Routes>
          <Route path="/service/:service" element={<Service />} />
          <Route path="/" element={<EventLandingPage />} />
        </Routes>
      </Router>
    </>
  );
}
