import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components";
import HeroService from "components/hero/HeroServices";
import Footer from "components/footers/SimpleFiveColumn.js";
import { useParams } from 'react-router-dom';
import LockChange from "images/services/lock-change.webp";
import House from "images/services/house.webp";
import CommercialChange from "images/services/commercial.webp";
import BussinesLockout from "images/services/business-lockout.webp";
import CarLockout from "images/services/car-lockout.webp";
import CarIgnition from "images/services/car-ignition.webp";
import CarKeyMake from "images/services/carkey-make.webp";
import FAQ from "components/faqs/SingleCol"
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { useState } from "react";
import { Container } from "components/misc/Layouts.js";
import { ReactComponent as ArrowRightIcon } from "images/arrow-right-icon.svg";

const Subheading = tw.span`uppercase tracking-wider text-sm`;
const Heading = tw(SectionHeading)`text-4xl my-4`;
const ContentWithPaddingXl = tw.div`max-w-screen-2xl mx-auto px-12 py-12 lg:py-24`;
const HeaderContainer = tw.span`flex flex-col md:flex-row p-0 md:p-4`;
const Form = styled.form`
  ${tw`bg-primary-900 p-8 rounded-3xl rounded-tl-none `}
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding:30px;

  input,
  select {
    ${tw`p-2 rounded-lg`}
    border: 1px solid #ccc;
    background-color: #f7f7f7;
    font-size: 18px;
    padding: 18px;
  }
  .title-form{
    font-weight: 700;
    color: #26ade4;
    margin: 0;
    margin-bottom: 20px;
    text-align: center;
    line-height: 1.625em;
    font-size: 18px;
  }
  
  .title-form strong{
    color: #FFFFFF;
  }
  button {
    ${tw`bg-red-600 text-white py-2 px-4 rounded-full rounded-tl-none cursor-pointer justify-center inline-flex items-center`}
    border: none;
    transition: background-color 0.3s ease;
    font-size: 18px;
    font-weight: bold;
    padding: 18px;
    .icon {
      ${tw`ml-2 w-5`}
    font-weight: bold;
    }
    &:hover {
      background-color: #ff6347;
    }
  }
`;
const OtherServices = styled.form`
  ${tw`p-8 rounded-lg mt-4`}
  background:#f2f6f7;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  h2{
    margin-bottom: 7px;
    font-size: 20px;
    font-weight: bold;
  }
  li{
    display: flex;
    justify-content: space-between;
    padding: 13px 0;
    border-bottom: 1px solid #e6e9ea;
    width: 100%;
    color: #7d8e9b;
  }
  li .icon {
      ${tw`ml-1 w-3`}
      color: #4299e1;
    }
  ul{
    list-style: none;
    margin: 0;
    padding: 0;
  }
`;
const ImageContainer = styled.span`
  ${tw`rounded-lg w-full md:w-3/5`}
  .img {
    ${tw`w-full h-56 px-8`}
  }
`;
const FormContainer = styled.span`
  ${tw`rounded-lg w-full md:w-2/5 md:px-12`}
  .form {
    ${tw`bg-primary-900 flex-col`}
  }
`;
const services = {
  carLockChange: {
    imageSrc: LockChange,
    title: "Residential Lock Change",
    description: "Need a residential lock change? Our expert locksmiths have you covered. Trust us for quick and reliable lock replacement services that ensure the security of your home. Contact us now.",
    url: "/service/car-lock-change",
    shortDescription: "Quick and reliable lock replacement services",
    faq: [
      {
        question: "How long does a lock change take?",
        answer: "The duration of a lock change depends on various factors such as the type of lock, the number of locks to be changed, and the complexity of the installation. On average, our locksmiths can complete a residential lock change within 30 to 60 minutes."
      },
      {
        question: "What types of locks do you work with?",
        answer: "Our locksmiths are experienced in working with a wide range of locks, including deadbolts, cylinder locks, mortise locks, and electronic keypad locks. We can recommend the most suitable lock type based on your specific needs and preferences."
      },
      {
        question: "Are your locksmiths licensed and insured?",
        answer: "Yes, all our locksmiths are licensed professionals and fully insured. You can have peace of mind knowing that your lock change will be handled by qualified experts who prioritize your security."
      }
    ]
  },
  residentialLockout: {
    imageSrc: House,
    title: "House Lockout",
    description: "Locked out of your house? Our expert locksmiths provide quick and damage-free solutions. Call us now for efficient assistance with your house lockout.",
    url: "/service/residential-lockout",
    shortDescription: "Efficient assistance with house lockouts",
    faq: [
      {
        question: "How long does it take to unlock a house door?",
        answer: "The time it takes to unlock a house door depends on the type of lock and the complexity of the situation. Our locksmiths strive to provide quick and efficient solutions, typically resolving a house lockout within 20 to 30 minutes."
      },
      {
        question: "Do you use any special tools to unlock a house door?",
        answer: "Our locksmiths are equipped with a variety of specialized tools and techniques to unlock different types of house doors. They use non-destructive methods whenever possible to ensure minimal damage to the lock or door."
      },
      {
        question: "What if my house lock is damaged during the unlocking process?",
        answer: "Our locksmiths are skilled in handling house lockouts without causing damage to the lock or door. However, in rare cases, if any damage occurs, our team will take responsibility for repairing or replacing the affected components."
      }
    ]
  },
  commercialLockChange: {
    imageSrc: CommercialChange,
    title: "Commercial Lock Change",
    description: "Upgrade your business security with our commercial lock change services. Our expert locksmiths provide efficient and reliable solutions. Contact us now for enhanced protection.",
    url: "/service/commercial-lock-change",
    shortDescription: "Efficient and reliable commercial lock change services",
    faq: [
      {
        question: "Can you handle large-scale lock changes for commercial properties?",
        answer: "Yes, we have experience in handling lock changes for commercial properties of all sizes. Whether you have a small office or a large retail space, our locksmiths can efficiently replace the locks to enhance your business security."
      },
      {
        question: "Can you recommend the best lock options for my commercial property?",
        answer: "Absolutely! Our locksmiths are knowledgeable about the latest security technologies and can provide expert recommendations for the most suitable lock options for your commercial property. We consider factors such as the type of business, access control requirements, and budget to ensure optimal security."
      },
      {
        question: "Do you offer emergency lock change services for commercial properties?",
        answer: "Yes, we understand the importance of maintaining the security of your commercial property. That's why we offer emergency lock change services, available 24/7. Whether you've experienced a security breach or need to replace locks urgently, our locksmiths are ready to assist you."
      }
    ]
  },
  commercialLockout: {
    imageSrc: BussinesLockout,
    title: "Commercial Lockout",
    description: "Locked out of your commercial property? Our skilled locksmiths provide fast and efficient solutions. Contact us now for assistance with your commercial lockout.",
    url: "/service/car-lock",
    shortDescription: "Fast and efficient solutions for commercial lockouts",
    faq: [
      {
        question: "How quickly can you resolve a commercial lockout?",
        answer: "Our locksmiths prioritize prompt service for commercial lockouts. On average, we aim to resolve a commercial lockout within 30 minutes or less, depending on the complexity of the lock and the circumstances."
      },
      {
        question: "Do you handle all types of commercial locks?",
        answer: "Yes, our locksmiths are experienced in working with various types of commercial locks, including cylinder locks, mortise locks, keypad locks, and high-security locks. We have the expertise to unlock and provide solutions for different lock mechanisms."
      },
      {
        question: "Is there a risk of damage to the commercial property during the lockout process?",
        answer: "Our locksmiths employ non-destructive techniques and tools to minimize any potential damage to the commercial property during a lockout. We prioritize a careful and professional approach to ensure the security of your premises."
      }
    ]
  },
  carLockout: {
    imageSrc: CarLockout,
    title: "Car Lockout",
    description: "Locked out of your car? Don't worry, our expert locksmiths are here to assist you. With our swift and reliable services, we'll have you back inside your vehicle in no time. Contact us now for efficient car lockout solutions.",
    url: "/service/car-lockout",
    shortDescription: "Swift and reliable car lockout solutions",
    faq: [
      {
        question: "How long does it take to unlock a car door?",
        answer: "The time it takes to unlock a car door depends on various factors, such as the type of lock, the make and model of the vehicle, and the complexity of the lockout situation. Our locksmiths strive to provide quick solutions, usually within 15 to 30 minutes."
      },
      {
        question: "Can you unlock all types of car locks?",
        answer: "Yes, our locksmiths have experience in unlocking a wide range of car locks, including traditional key locks, electronic locks, and smart locks. We have the tools and techniques to safely and efficiently handle car lockouts."
      },
      {
        question: "Will unlocking my car door cause any damage?",
        answer: "Our locksmiths use specialized tools and techniques that are designed to minimize the risk of damage to your car during a lockout. We prioritize careful and non-destructive methods to ensure the integrity of your vehicle."
      }
    ]
  },
  carKeyMake: {
    imageSrc: CarKeyMake,
    title: "Car Key Made/Program",
    description: "Get a new car key made or programmed by our skilled locksmiths. Whether you've lost your key or need a spare, we offer reliable and efficient car key services. Contact us now for assistance.",
    url: "/service/car-key-make-program",
    shortDescription: "Reliable car key making and programming services",
    faq: [
      {
        question: "What types of car keys can you make or program?",
        answer: "Our locksmiths can make or program a variety of car keys, including traditional metal keys, transponder keys, key fobs, and proximity keys. We have the tools and expertise to work with different car key technologies."
      },
      {
        question: "Do you provide car key services for all car makes and models?",
        answer: "Yes, we offer car key services for a wide range of car makes and models. Our locksmiths stay updated with the latest key technologies and have the knowledge to work with various vehicle manufacturers."
      },
      {
        question: "Can you replace a lost car key without the original key?",
        answer: "Yes, we can replace a lost car key even if you don't have the original key. Our locksmiths have the necessary equipment and programming tools to create a new key based on your car's specifications."
      }
    ]
  },
  carIgnition: {
    imageSrc: CarIgnition,
    title: "Car Ignition Repair/Replacement",
    description: "Experiencing ignition problems? Our skilled locksmiths provide reliable car ignition repair and replacement services. Get your car back on the road smoothly. Contact us now.",
    url: "/service/car-ignition-repair-replacement",
    shortDescription: "Reliable car ignition repair and replacement services",
    faq: [
      {
        question: "What are common signs of a faulty car ignition?",
        answer: "Common signs of a faulty car ignition include difficulty starting the car, the key getting stuck in the ignition, intermittent power loss, and engine stalling. If you're experiencing any of these issues, our locksmiths can diagnose and repair the ignition."
      },
      {
        question: "Do you repair or replace car ignitions?",
        answer: "Our locksmiths have the expertise to both repair and replace car ignitions. The appropriate solution depends on the extent of the ignition problem. We will assess the situation and provide the most suitable course of action."
      },
      {
        question: "Can you work with ignitions for all car makes and models?",
        answer: "Yes, our locksmiths are knowledgeable about various car makes and models, and they can work with different ignition systems. Whether you have a domestic or foreign vehicle, we have the skills to handle your car's ignition."
      }
    ]
  }
};



const Service = () => {
  const { service } = useParams();
  const formattedService = service.replace(/-([a-z])/g, (match, letter) => letter.toUpperCase());
  const selectedService = services[formattedService];

  // State for form inputs
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [selectedOption, setSelectedOption] = useState("");

  // Function to handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    // TODO: Handle form submission logic here
    console.log("Form submitted:", name, phone, selectedOption);
  };

  console.log(service)
  console.log(formattedService)
  return (
    <AnimationRevealPage>
      <HeroService selectedService={selectedService}></HeroService>

      <Container>
        <ContentWithPaddingXl>
          <HeaderContainer>
            <ImageContainer className="imageContainer" >
              <img src={selectedService.imageSrc} alt={selectedService.title} loading="lazy" className="img" />
              <FAQ faqs={selectedService.faq}></FAQ>
            </ImageContainer>
            <FormContainer>
              <Form onSubmit={handleSubmit} className="form">
                <p className="title-form">
                  <strong>Our locksmiths are standing by!</strong><br></br>
                  Enter your info to request service
                </p>
                <input
                  type="text"
                  placeholder="Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <input
                  type="tel"
                  placeholder="Phone"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
                <select
                  value={selectedOption}
                  onChange={(e) => setSelectedOption(e.target.value)}
                >
                  <option value="" disabled>
                    Select a service
                  </option>
                  {Object.keys(services).map((serviceKey) => (
                    <option key={serviceKey} value={serviceKey}>
                      {services[serviceKey].title}
                    </option>
                  ))}
                </select>
                <button type="submit">
                  Request Service
                  <ArrowRightIcon className="icon" />
                </button>
              </Form>
              <OtherServices>
                <h2>Popular Services</h2>
                <ul >
                  {Object.keys(services).map((serviceKey) => (
                    <li key={serviceKey} value={serviceKey}>
                      <a>{services[serviceKey].title}</a>
                    <ArrowRightIcon className="icon" />
                    </li>
                  ))}
                </ul>
              </OtherServices>
            </FormContainer>
          </HeaderContainer>

        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};

export default Service;
