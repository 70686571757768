import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import { SectionHeading } from "components/misc/Headings.js";
import SupportIconImage from "../../images/support-icon.svg";
import ShieldIconImage from "../../images/shield-icon.svg";
import CustomizeIconImage from "../../images/customize-icon.svg";
import SimpleIconImage from "../../images/simple-icon.svg";

const Container = tw.div`relative`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto p-8 md:py-24`}
`;
const Heading = tw(SectionHeading)`w-full`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/2 flex`}
`;

const Card = styled.div`
  ${tw`flex flex-col mx-auto items-start px-6 py-10 bg-gray-200 rounded-xl mt-12`}
  .imageContainer {
    ${tw`text-center rounded-full p-6 flex-shrink-0 relative`}
    img {
      ${tw`w-8 h-8`}
    }
  }
  .textContainer {
    ${tw`mt-6 text-start`}
  }
  .number {
    ${tw`mt-2 font-bold text-3xl leading-none text-primary-500`}
  }
  .title {
    ${tw`mt-2 font-medium text-xl leading-none text-gray-800`}
  }

  .description {
    ${tw`mt-3 font-semibold text-secondary-100 text-sm leading-loose`}
  }
`;

export default () => {

  const cards = [
    {
      imageSrc: ShieldIconImage,
      title: "Years of Experience",
      number: "7+",
      description: "We have provided nearly 1 decade of locksmith services for our customers."
    },
    {
      imageSrc: SupportIconImage,
      title: "Licensed Technicians",
      number: "10",
      description: "All locksmiths are trained and credentialed in compliance with local laws."
    },
    {
      imageSrc: CustomizeIconImage,
      title: "Locations Served",
      number: "15",
      description: "Our network is always expanding to offer quality locksmithing nationwide."
    },
    {
      imageSrc: SimpleIconImage,
      title: "Customer Support",
      number: "24-hour",
      description: "Our staff is always standing by to help no matter when you need a locksmith."
    }
  ];

  return (
    <Container>
      <ThreeColumnContainer>
        <Heading>Many reasons to <span tw="text-primary-500">choose us first</span></Heading>
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              <span className="number">{card.number || "8,467"}</span>
              <span className="textContainer">
                <span className="title">{card.title || "Fully Secure"}</span>
                <p className="description">
                  {card.description || "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud. Sic Semper Tyrannis. Neoas Calie artel."}
                </p>
              </span>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
    </Container>
  );
};
