import React, { useState } from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line

import useAnimatedNavToggler from "../../helpers/useAnimatedNavToggler.js";

import logo from "../../images/logo.svg";
import { ReactComponent as MenuIcon } from "feather-icons/dist/icons/menu.svg";
import { ReactComponent as CloseIcon } from "feather-icons/dist/icons/x.svg";

const Header = tw.header`
  flex justify-between items-center mx-auto lg:pl-8
`;

export const NavLinks = tw.div`inline-block`;

export const NavLink = tw.a`
  text-lg my-2 lg:text-base lg:mx-6 lg:my-0
  font-semibold tracking-wide transition duration-300
  pb-1 border-b-2 border-transparent hover:border-primary-500 hocus:text-primary-500
`;

export const PrimaryLink = tw(NavLink)`
  lg:mx-0 px-8 py-3 rounded bg-primary-500 text-gray-100
  hocus:bg-primary-700 hocus:text-gray-200 focus:shadow-outline
  border-b-0
`;

export const LogoLink = styled(NavLink)`
  ${tw`flex items-center font-black border-b-0 text-2xl! ml-0! `};

  img {
    ${tw`w-56`}
  }
`;

export const MobileNavLinksContainer = tw.nav`flex flex-1 items-center justify-between`;
export const NavToggle = tw.button`
  lg:hidden z-20 focus:outline-none hocus:text-primary-500 transition duration-300
`;
export const CTA = styled.button`
  z-index: 20;
  outline: none;
  right: 0;
  background-color: #f2f6f7;
  max-height: 10vh;
  display: flex;
  align-items: center;
  padding: 2rem;
  justify-content: center;
  border: none;
  border-top-left-radius: 2rem;


  .emergency-symbol {
    height: 56px;
    width: 56px;
    border: 2px solid #26ade4;
    border-radius: 50%; /* Utilizar 50% para crear un círculo */
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.3rem; /* Ajustar el tamaño del icono según sea necesario */
    color: #26ade4; 
    margin-right: 10px;
    transform: rotate(90deg); /* Rotar el icono 180 grados */
  }
  .emergency-text {
    font-size: 1rem;
    font-weight: 600;
    color: #7d8e9b;
  }
  .emergency-phone {
    font-size: 1.25rem;
    color: #00374c;
    font-weight: 700;
  }
  `;
export const MobileNavLinks = motion(styled.div`
  ${tw`lg:hidden z-10 fixed top-0 inset-x-0 mx-4 my-6 p-8 border text-center rounded-lg text-gray-900 bg-white`}
  ${NavLinks} {
    ${tw`flex flex-col items-center`}
  }
`);

export const DesktopNavLinks = tw.nav`
  hidden lg:flex flex-1 justify-between items-center
`;

const DropdownContainer = styled.div`
  ${tw`absolute left-0 mt-2 py-2 bg-white rounded-lg shadow-lg`}
`;

export const DropdownItem = tw.a`
  block px-4 py-2 text-sm text-gray-800 hover:bg-primary-500 hover:text-white
`;

const servicesDropdownOptions = [
  { label: "Option 1", href: "/option1" },
  { label: "Option 2", href: "/option2" },
  { label: "Option 3", href: "/option3" },
  // Agrega más opciones si es necesario
];

export default ({ roundedHeaderButton = false, logoLink, links, className, collapseBreakpointClass = "lg" }) => {
  const [isServicesDropdownOpen, setServicesDropdownOpen] = useState(false);

  const handleServicesDropdownToggle = () => {
    setServicesDropdownOpen(!isServicesDropdownOpen);
  };
  const { showNavLinks, animation, toggleNavbar } = useAnimatedNavToggler();
  const collapseBreakpointCss = collapseBreakPointCssMap[collapseBreakpointClass];
  const defaultLinks = [
    <NavLinks key={1}>
      <NavLink
        href="/#"
        onMouseEnter={handleServicesDropdownToggle}
        onMouseLeave={handleServicesDropdownToggle}
        onFocus={handleServicesDropdownToggle}
        onBlur={handleServicesDropdownToggle}
      >
        Services
        {isServicesDropdownOpen && (
          <DropdownContainer>
            {servicesDropdownOptions.map((option, index) => (
              <DropdownItem key={index} href={option.href}>
                {option.label}
              </DropdownItem>
            ))}
          </DropdownContainer>
        )}
      </NavLink>
      <NavLink href="/#">Locations</NavLink>
      <NavLink href="/#">About</NavLink>
      <NavLink href="/#">Contact</NavLink>
    </NavLinks>
  ];
  const defaultLogoLink = (
    <LogoLink href="/">
      <img src={logo} alt="logo" />
    </LogoLink>
  )

  return (
    <Header className={className || "header-light"}>
      <DesktopNavLinks css={collapseBreakpointCss.desktopNavLinks}>
        {defaultLogoLink}
        {defaultLinks}
        <CTA>
          <div class="emergency-symbol">
            <i class="fas fa-phone-alt"></i>
          </div>
          <div>
            <a class="emergency-cta" href="tel:+1 (866) 338-9997">
              <div class="emergency-content">
                <div class="emergency-text">24-hr Emergency Line</div>
                <div class="emergency-phone">(866) 338-9997</div>
              </div>
            </a>
          </div>
        </CTA>
      </DesktopNavLinks>

      <MobileNavLinksContainer css={collapseBreakpointCss.mobileNavLinksContainer}>
        {logoLink}
        <MobileNavLinks initial={{ x: "150%", display: "none" }} animate={animation} css={collapseBreakpointCss.mobileNavLinks}>
          {links}
        </MobileNavLinks>
        <NavToggle onClick={toggleNavbar} className={showNavLinks ? "open" : "closed"}>
          {showNavLinks ? <CloseIcon tw="w-6 h-6" /> : <MenuIcon tw="w-6 h-6" />}
        </NavToggle>
      </MobileNavLinksContainer>
    </Header>
  );
};

const collapseBreakPointCssMap = {
  sm: {
    mobileNavLinks: tw`sm:hidden`,
    desktopNavLinks: tw`sm:flex`,
    mobileNavLinksContainer: tw`sm:hidden`,
  },
  md: {
    mobileNavLinks: tw`md:hidden`,
    desktopNavLinks: tw`md:flex`,
    mobileNavLinksContainer: tw`md:hidden`,
  },
  lg: {
    mobileNavLinks: tw`lg:hidden`,
    desktopNavLinks: tw`lg:flex`,
    mobileNavLinksContainer: tw`lg:hidden`,
  },
  xl: {
    mobileNavLinks: tw`lg:hidden`,
    desktopNavLinks: tw`lg:flex`,
    mobileNavLinksContainer: tw`lg:hidden`,
  },
};
