import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import Header, { NavLink, NavLinks, PrimaryLink as PrimaryLinkBase, LogoLink, NavToggle, DesktopNavLinks } from "../headers/light.js";
import HomeDoor from "images/homeDoor.avif"

const StyledHeader = styled(Header)`
  ${tw`max-w-none w-full h-10`}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw`text-gray-800 hover:border-gray-300 hover:text-primary-300`}
  }
  ${NavToggle}.closed {
    ${tw`text-gray-100 hover:text-primary-500`}
  }
`;

const Hello = styled.div`
  ${tw`relative bg-blue-900 h-60 sm:h-85 p-4`}
`;
const HelloContent = styled.div`
  ${tw`relative flex h-full flex-col justify-center items-center z-10 text-white p-0 sm:p-40`}
`;
const OpacityOverlay = tw.div`z-10 absolute inset-0 bg-gradient-to-t from-blue-1000 to-blue-1100 opacity-100`;

const HelloBackground = styled.div`

${tw`absolute top-0 right-0 w-full h-full bg-blue-100 bg-bottom bg-cover overflow-hidden`}
background-image: url(${HomeDoor});
`;

const PrimaryButton = tw(PrimaryButtonBase)`
my-8 flex w-56 tracking-wide text-center py-5 bg-white text-primary-100 items-center justify-center
`;
const Heading = styled.h1`
  ${tw`text-4xl text-center sm:text-3xl lg:text-5xl xl:text-5xl font-hairline text-white leading-snug sm:text-start`}
  span {
    ${tw` mt-2 font-black`}
  }
`;

export default () => {
  return (
    <>
      <StyledHeader />
      <Hello>
        <HelloBackground loading="lazy">
          <OpacityOverlay />
          <HelloContent>
            <Heading>Your Trusted &amp; Affordable<br /><span>Locksmith Professionals</span></Heading>
            <PrimaryButton as="a" href="tel:+1 (312) 847-6008">
              Call Us Now
            </PrimaryButton>
            <h3>24-hour support: (312) 847-6008</h3>
          </HelloContent>
        </HelloBackground>
      </Hello>
    </>
  );
};
