import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line

import LogoImage from "../../images/logo-light-02.svg";
import { ReactComponent as FacebookIcon } from "../../images/facebook-icon.svg";
import { ReactComponent as TwitterIcon } from "../../images/twitter-icon.svg";
import { ReactComponent as YoutubeIcon } from "../../images/youtube-icon.svg";

const Container = tw.div`relative bg-primary-900 -mx-8 px-8 z-30`;
const FiveColumns = tw.div`max-w-screen-xl mx-auto py-16 lg:py-20 flex flex-wrap justify-between`;

const Column = tw.div`w-full md:w-1/5 text-center md:text-start mb-4 md:mb-0`;
const Mobile = styled.div`
  ${tw`w-full bg-primary-100 text-xl text-white text-center py-4 fixed bottom-0 left-0`}
`;
const Mobile2 = styled.div`
  ${tw`w-full bg-primary-100 text-xl text-white text-center py-4 bottom-0 left-0`}
`;
const WideColumn = tw(Column)`text-center md:text-left w-full md:w-2/5 mb-10 md:mb-0`;

const ColumnHeading = tw.h5`font-bold text-white text-2xl	`;

const LinkList = tw.ul`mt-4 text-base font-medium text-gray-600`;
const LinkListItem = tw.li`mt-3`;
const Link = tw.a`border-b-2 border-transparent hocus:text-primary-500 hocus:border-primary-500 pb-1 transition duration-300`;

const LogoContainer = tw.div`flex items-center justify-center md:justify-start`;
const LogoImg = tw.img`w-64`;
const LogoText = tw.h5`ml-2 text-xl font-black text-primary-500`;

const CompanyDescription = tw.p`mt-4 max-w-xs font-medium text-lg mx-auto md:mx-0 md:mr-4 text-gray-600`;

const SocialLinksContainer = tw.div`mt-4 `;
const SocialLink = styled.a`
  ${tw`cursor-pointer inline-block p-2 rounded-full bg-primary-100 text-gray-100 hover:bg-gray-900 transition duration-300 mr-4`}
  svg {
    ${tw`w-6 h-6`}
  }
`;

export default () => {
  return (
    <Container>
      <FiveColumns>
        <WideColumn>
          <LogoContainer>
            <LogoImg src={LogoImage} alt="logo" />
          </LogoContainer>
          <CompanyDescription>
            Simply Great Locksmith is a seasoned locksmith company dedicated to delivering exceptional and cost-effective lock and security solutions across the nation.
          </CompanyDescription>
          <SocialLinksContainer>
            <SocialLink href="https://facebook.com" aria-label="Facebook">
              <FacebookIcon alt="Facebook Icon" />
            </SocialLink>
            <SocialLink href="https://twitter.com" aria-label="Twitter">
              <TwitterIcon alt="Twitter Icon" />
            </SocialLink>
            <SocialLink href="https://youtube.com" aria-label="YouTube">
              <YoutubeIcon alt="YouTube Icon" />
            </SocialLink>
          </SocialLinksContainer>
        </WideColumn>
        <Column>
          <ColumnHeading>Services</ColumnHeading>
          <LinkList>
            <LinkListItem>
              <Link href="#">Blog</Link>
            </LinkListItem>
            <LinkListItem>
              <Link href="#">FAQs</Link>
            </LinkListItem>
            <LinkListItem>
              <Link href="#">Support</Link>
            </LinkListItem>
            <LinkListItem>
              <Link href="#">About Us</Link>
            </LinkListItem>
          </LinkList>
        </Column>
        <Column>
          <ColumnHeading>Locations</ColumnHeading>
          <LinkList>
            <LinkListItem>
              <Link href="#">Log In</Link>
            </LinkListItem>
            <LinkListItem>
              <Link href="#">Personal</Link>
            </LinkListItem>
            <LinkListItem>
              <Link href="#">Business</Link>
            </LinkListItem>
            <LinkListItem>
              <Link href="#">Team</Link>
            </LinkListItem>
          </LinkList>
        </Column>
        <Column>
          <ColumnHeading>Company</ColumnHeading>
          <LinkList>
            <LinkListItem>
              <Link href="#">GDPR</Link>
            </LinkListItem>
            <LinkListItem>
              <Link href="#">Privacy Policy</Link>
            </LinkListItem>
            <LinkListItem>
              <Link href="#">Terms of Service</Link>
            </LinkListItem>
            <LinkListItem>
              <Link href="#">Disclaimer</Link>
            </LinkListItem>
          </LinkList>
        </Column>
      </FiveColumns>

      <Mobile2 alt="PhoneCall">
        <a href="tel:+1 (312) 847-6008" title="Click to call our phone number">Call us: <strong>(312) 847-6008</strong></a>
      </Mobile2>
      <Mobile alt="PhoneCall">
        <a href="tel:+1 (312) 847-6008" title="Click to call our phone number">Call us: <strong>(312) 847-6008</strong></a>
      </Mobile>
    </Container>
  );
};
