import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { ReactComponent as ArrowRightIcon } from "images/arrow-right-icon.svg";
import Residential from "images/residential.webp"
import Header, { NavLink, NavLinks, PrimaryLink as PrimaryLinkBase, LogoLink, NavToggle, DesktopNavLinks } from "../headers/light.js";

const PrimaryLink = tw(PrimaryLinkBase)`rounded-full`
const Hello = styled.div`
  ${tw`relative bg-gray-200 flex flex-col justify-center p-8`}
`;
const HelloContent = styled.div`
  ${tw`relative flex h-full flex-col z-10 text-gray-800 justify-around  px-10 sm:p-40  bg-gray-200 to-transparent opacity-100`}
`;
const SectionContainer = styled.div`
  ${tw`relative mx-auto px-0 max-w-6xl box-border`}
`;
const OpacityOverlay = tw.div`z-10 absolute inset-0 bg-gray-200 to-transparent opacity-100`;

const HelloBackground = styled.div`

${tw`absolute top-0 right-0 w-full sm:w-2/3 h-full bg-blue-100 bg-bottom bg-cover overflow-hidden`}
sm:background-image: url(${Residential});
`;
const Content = tw.div`px-4 flex flex-1 flex-col justify-center items-center`;

const Heading = styled.h1`
  ${tw`text-3xl my-4 text-center sm:text-5xl lg:text-5xl xl:text-5xl font-hairline text-gray-800 leading-snug  sm:mt-0 `}
  span {
    ${tw`inline-block mt-2 font-black`}
  }
`;

export default () => {
  return (
    <>
      <Hello>
        <Heading>About <span tw="text-primary-500 font-bold">our company</span></Heading>
        <h3 tw="font-bold my-4 text-center">We are a reputable locksmith company that specializes in providing reliable and efficient locksmith services.</h3>
        <p tw="text-center">With over 7 years of experience, our newly established locksmith company in California is dedicated to delivering professional local locksmith services. Our team of skilled technicians is strategically located throughout the state, allowing us to promptly connect you with the finest locksmith in your area. Whether you require immediate assistance or prefer to schedule an appointment, we are committed to providing you with the highest level of service at your convenience.</p>
        <span tw="inline-flex items-center py-5 px-10 mt-5 mx-auto rounded-full bg-primary-100 text-lg font-bold text-white leading-none hocus:text-primary-900 transition duration-300 rounded-tl-none	" className="link">
          <span>Learn More</span>
          <ArrowRightIcon className="icon" tw="ml-2 w-5" />
        </span>
      </Hello>
    </>
  );
};
