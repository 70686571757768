import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { Container } from "components/misc/Layouts.js";
import { ReactComponent as ArrowRightIcon } from "images/arrow-right-icon.svg";
import SupportIconImage from "images/support-icon.svg";
import ShieldIconImage from "images/shield-icon.svg";
import CustomizeIconImage from "images/customize-icon.svg";
import LockChange from "images/services/lock-change.webp";
import House from "images/services/house.webp";
import CommercialChange from "images/services/commercial.webp";
import BussinesLockout from "images/services/business-lockout.webp";
import CarLockout from "images/services/car-lockout.webp";
import CarIgnition from "images/services/car-ignition.webp";
import CarKeyMake from "images/services/carkey-make.webp";

const ContentWithPaddingXl= tw.div`max-w-screen-2xl mx-auto px-8 py-12 lg:py-24`;
const Heading = tw(SectionHeading)`text-3xl`;
const Subheading = tw(SubheadingBase)`text-center mb-3 font-black`;
const Description = tw(SectionDescription)`text-center mx-auto`;
const SectionContainer = styled.div`
  ${tw`mt-10 flex flex-col items-center lg:items-stretch lg:flex-row flex-wrap lg:justify-center w-full mx-auto`}
  gap: 70px; /* Add the desired gap value between the Column components */
`;
const Column = styled.div`
  ${tw`lg:w-1/4 max-w-sm`} /* Change the width to lg:w-1/4 or lg:w-1/5 */
`;

const Card = styled.a`
  ${tw`flex flex-col items-center text-center w-full py-4 rounded transition-transform duration-300 hover:cursor-pointer transform `}
  .service-image {
    ${tw`text-start mb-5 mr-auto`}
    img {
      ${tw`w-full rounded-xl `}
    }
  }
  .title {
    ${tw`font-medium text-2xl leading-none text-left`}
  }
  .description {
    ${tw`mt-4 mb-5 font-medium text-gray-600 leading-relaxed text-base text-left`}
  }
  .service-cta {
    ${tw`inline-flex items-center py-5 px-10 mr-auto rounded-full rounded-tl-none bg-primary-100 text-lg font-bold text-white leading-none hocus:bg-primary-900 transition duration-300`}
    .icon {
      ${tw`ml-2 w-5`}
    }
  }
`;

export default ({
  cards = [
    {
      imageSrc: LockChange,
      title: "Residential Lock Change",
      description: "Need a residential lock change? Our expert locksmiths have you covered. Trust us for quick and reliable lock replacement services that ensure the security of your home. Contact us now.                                                              ",
      url: "/service/car-lock-change",
    },
    {
      imageSrc: House,
      title: "House Lockout",
      description: "If you are locked out of your house, apartment, or any other residential space, call a home locksmith. Our team is prepared to help open your locked door.",
      url: "/service/residential-lockout",
    },
    {
      imageSrc: CommercialChange,
      title: "Commercial Lock Change",
      description: "Upgrade your business security with our commercial lock change services. Our expert locksmiths provide efficient and reliable solutions. Contact us now for enhanced protection.                                                          ",
      url: "/service/commercial-lock-change",
    },
    {
      imageSrc: BussinesLockout,
      title: "Commercial Lockout",
      description: "Locked out of your commercial property? Our skilled locksmiths provide fast and efficient solutions. Contact us now for assistance with your commercial lockout.                                                                   ",
      url: "/service/commercial-lockout",
    },
    {
      imageSrc: CarLockout,
      title: "Car Lockout",
      description: "When your keys are locked in your car, car door locks won't open, etc., you need an automotive locksmith. Open your car fast and get back on the road.",
      url: "/service/car-lockout",
    },
    {
      imageSrc: CarKeyMake,
      title: "Car Key Made/Program",
      description: "Get a new car key made or programmed hassle-free. Our expert locksmiths specialize in efficient car key services. Contact us now to get back on the road swiftly.                                                                       ",
      url: "/service/car-key-made",
    },
    {
      imageSrc: CarIgnition,
      title: "Car Ignition Fix",
      description: "Ignition cylinders break in many ways, and sometimes replacement is the only option. A car locksmith can fix the damage and make the vehicle drivable again.",
      url: "/service/car-ignition",
    },
  ],
  linkText = "Learn more",
  heading = "",
  subheading = "",
  description = "",
  imageContainerCss = null,
  imageCss = null
}) => {
  return (
    <Container>
      <ContentWithPaddingXl>
        {subheading && <Subheading>{subheading}</Subheading>}
        {<Heading>Solutions for your <span tw="text-primary-500">lock & security needs</span></Heading>}
        {description && <Description>{description}</Description>}
        <SectionContainer>
          {cards.map((card, i) => (
            <Column key={i}>
              <Card href={card.url}>
                <div className="service-image">
                  <img src={card.imageSrc} alt={`${card.imageSrc}`} loading="lazy" />
                </div>
                <div className="service-text">
                  <h3 className="title">{card.title}</h3>
                  <p className="description">{card.description}</p>
                </div>
                {linkText && (
                  <div className="service-cta">
                    <span>{linkText}</span>
                    <ArrowRightIcon className="icon" />
                  </div>
                )}
              </Card>
            </Column>
          ))}
        </SectionContainer>
      </ContentWithPaddingXl>
    </Container>
  );
};
